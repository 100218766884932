import { createSlice } from '@reduxjs/toolkit';
import { getDresdenGOData, getFramesData, getGlassesData } from 'functions/getGlassesData';
import { getProductType } from 'functions/productType';
import parse from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';
import {
  extraDataPrice,
  extraDataSinglePrice,
  itemSubTotal,
  itemSubTotalTax,
  noExtraDataPrice,
  noExtraDataSinglePrice,
  noExtraDataSubTotal,
  shippingRateWithTax,
  totalBundleDiscount
} from '../../../functions/bagPrice';
import { cartMergeFunction } from '../../../functions/cart/cartMergeFunction';
import { makeDiscount } from '../../../functions/makeDiscount';
import { capitalize, titleCase } from 'functions/capitalize';
import { describe } from 'node:test';

export type bagState = {
  bagOpen: boolean;
  loading: boolean;
  simpleLoading: boolean;
  count: number;
  bag: any;
  block: boolean;
  refetchQ: boolean;
  updateElement: any;
  updateElementFrame: any;
  updateElementLoading: boolean;
  lastQueueItem: boolean;
  runQueue: boolean;
  firstRunQueue: boolean;
  paymentLoading: boolean;
  paymentError: boolean;
  paymentErrorMessage: string;
  freeShipping: boolean;
  estimatedShippingPrice: number;
  coupons: any;
  showPeopleAlsoBoughtModal: boolean;
  isShowPeopleAlsoBoughtModalOpenBefore: boolean;
};

let totalCountInit: number = 0;
if (typeof window !== 'undefined' && localStorage.getItem('local-basket')) {
  JSON.parse(localStorage.getItem('local-basket') || '{}').items.map(
    (item: any) => {
      totalCountInit += item[0].quantity;
    }
  );
}

let totalPrice: number = 0;

if (typeof window !== 'undefined' && localStorage.getItem('local-basket')) {
  JSON.parse(localStorage.getItem('local-basket') || '{}').items.map(
    (it: any) => {
      totalPrice += it[0].price;
    }
  );
}

let coupons: any = [];

if (typeof window !== 'undefined' && localStorage.getItem('coupons')) {
  coupons = JSON.parse(localStorage.getItem('coupons') || '{}');
}

let isShowPeopleAlsoBoughtModalOpenBefore: boolean = false;
if (typeof window !== 'undefined' && localStorage.getItem('isShowPeopleAlsoBoughtModalOpenBefore')) {
  isShowPeopleAlsoBoughtModalOpenBefore = JSON.parse(localStorage.getItem('isShowPeopleAlsoBoughtModalOpenBefore') || 'false');
}

const initialState: bagState = {
  bagOpen: false,
  loading: false,
  block: false,
  refetchQ: false,
  simpleLoading: false,
  runQueue: false,
  lastQueueItem: false,
  showPeopleAlsoBoughtModal: false,
  isShowPeopleAlsoBoughtModalOpenBefore: isShowPeopleAlsoBoughtModalOpenBefore,
  paymentErrorMessage: 'Payment processing failed. Please retry.',
  count: 0,
  firstRunQueue: false,
  bag:
    typeof window !== 'undefined'
      ? {
        availableShippingMethods: [
          {
            rates: [
              {
                methodId: 'flat_rate',
                label: 'Flat rate',
                id: 'flat_rate:16',
                cost: '10.00',
                __typename: 'ShippingRate'
              },
              {
                methodId: 'free_shipping',
                label: 'Free shipping',
                id: 'free_shipping:17',
                cost: '0.00',
                __typename: 'ShippingRate'
              }
            ],
            __typename: 'ShippingPackage'
          }
        ],
        displayPricesIncludeTax: true,
        chosenShippingMethods: null,
        shippingTotal: 0,
        shippingWithTax: 0,
        subtotalTax: 0,
        discountTotal: 0,
        priceTotal: 0,
        currency: '$',
        subtotal: totalPrice,
        bundleDiscount: 0,
        total: totalPrice,
        appliedCoupons: [],
        appliedGiftUp: [],
        items: localStorage.getItem('local-basket')
          ? JSON.parse(localStorage.getItem('local-basket') || '{}').items
          : []
      }
      : {},
  updateElement: null,
  updateElementFrame: null,
  updateElementLoading: false,
  paymentLoading: false,
  paymentError: false,
  freeShipping: false,
  estimatedShippingPrice: 10,
  coupons: coupons,
};

const setCoupons = (bagItems: any) => {
  var coupons: any = [];
  if (bagItems.appliedCoupons) {
    coupons.push(...bagItems.appliedCoupons);
  }
  if (Number(bagItems.bundleDiscount) !== 0) {
    var bundle_code = 'bundle_discount';

    coupons.push({ code: bundle_code, discountAmount: bagItems.bundleDiscount });
  }
  if (bagItems.appliedGiftUp) {
    coupons.push(...bagItems.appliedGiftUp);
  }

  if (coupons.length === 0) {
    localStorage.setItem(
      'coupons',
      JSON.stringify([])
    );
  }
  localStorage.setItem(
    'coupons',
    JSON.stringify(coupons)
  );
}

export const bagSlice = createSlice({
  name: 'bagEvent',
  initialState,
  reducers: {
    startQueue: (state) => {
      state.runQueue = !state.runQueue;
    },
    open: (state) => {
      state.bagOpen = true;
    },
    close: (state) => {
      state.bagOpen = false;
    },
    updateItem: (state, action) => {
      if (action.payload || action.payload === null) {
        state.updateElement = action.payload;
      }
    },
    setUpdateElementFrame: (state, action) => {
      if (action.payload || action.payload === null) {
        state.updateElementFrame = action.payload;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRefecthQ: (state, action) => {
      state.refetchQ = action.payload;
    },
    setBlock: (state, action) => {
      state.block = action.payload;
    },
    setSimpleLoading: (state, action) => {
      state.simpleLoading = action.payload;
    },
    setLoadingUpdate: (state, action) => {
      state.updateElementLoading = action.payload;
    },
    setLastQueueItem: (state, action) => {
      state.lastQueueItem = action.payload;
    },
    updateCount: (state, action) => {
      state.count = action.payload;
    },
    updateBag: (state, action) => {
      state.bag.items = action.payload.items;
      let totalPriceU: number = 0;
      let totalBundleDiscount: number = 0;
      makeDiscount(action.payload.items);
      action.payload.items.map((it: any) => {
        totalPriceU += it[0].price;
        if (it[0].subTotal) {
          totalBundleDiscount += Number(it[0].subTotal);
        } else {
          totalBundleDiscount += Number(it[0].price);
        }
      });

      state.bag.subtotal = totalPriceU;
      state.bag.bundleDiscount = totalPriceU - totalBundleDiscount;

      let totalCount: number = 0;
      action.payload.items.map((item: any) => {
        totalCount += item[0].quantity;
      });
      state.count = totalCount;

      if (typeof window !== 'undefined') {
        if (action.payload.items.length > 0) {
          localStorage.setItem(
            'local-basket',
            JSON.stringify({ items: action.payload.items })
          );
        } else {
          localStorage.removeItem('local-basket');
          state.bag.subtotal = 0;
          state.bag.total = 0;
          state.bag.priceTotal = 0;
          state.bag.bundleDiscount = 0;
        }
      }
      setCoupons(state.bag);
    },
    removeItem: (state, action) => {
      const filteredItems = state.bag.items.filter(
        (itm: any) => itm[0].uuid !== action.payload.uuid
      );
      state.bag.items = filteredItems;
      let totalPriceR: number = 0;
      let totalBundleDiscount: number = 0;
      makeDiscount(filteredItems);
      filteredItems.map((it: any) => {
        totalPriceR += it[0].price;
        if (it[0].subTotal) {
          totalBundleDiscount += Number(it[0].subTotal);
        } else {
          totalBundleDiscount += Number(it[0].price);
        }
      });

      state.bag.subtotal = totalPriceR;
      state.bag.bundleDiscount = totalPriceR - totalBundleDiscount;

      let totalCount: number = 0;
      filteredItems.map((item: any) => {
        totalCount += item[0].quantity;
      });
      state.count = totalCount;
      if (typeof window !== 'undefined') {
        if (filteredItems.length > 0) {
          localStorage.setItem(
            'local-basket',
            JSON.stringify({
              items: filteredItems
            })
          );
        } else {
          localStorage.removeItem('local-basket');
        }
      }
    },
    quantityUpdate: (state, action) => {
      const newItems = state.bag.items.map((itm: any) => {
        if (itm[0].uuid === action.payload.uuid) {
          const itemTemp = itm;
          itemTemp[0].quantity = itemTemp[0].quantity + action.payload.quantity;
          itemTemp[0].price = itemTemp[0].singlePrice * itemTemp[0].quantity;
          return itemTemp;
        } else {
          return itm;
        }
      });
      state.bag.items = newItems;
      let totalCount: number = 0;
      newItems.map((item: any) => {
        totalCount += item[0].quantity;
      });
      let totalPriceQ: number = 0;
      let totalBundleDiscount: number = 0;
      makeDiscount(newItems);
      newItems.map((it: any) => {
        totalPriceQ += it[0].price;
        if (it[0].subTotal) {
          totalBundleDiscount += it[0].subTotal;
        } else {
          totalBundleDiscount += it[0].price;
        }
      });
      state.bag.subtotal = totalPriceQ;
      state.bag.bundleDiscount = Number(totalPriceQ - totalBundleDiscount);

      state.count = totalCount;
      if (typeof window !== 'undefined') {
        if (newItems) {
          localStorage.setItem(
            'local-basket',
            JSON.stringify({
              items: newItems
            })
          );
        } else {
          localStorage.removeItem('local-basket');
        }
      }
    },
    updateShoppingCartWithRequest: (state, action) => {
      if (action.payload.cart) {
        state.lastQueueItem = false;
        const nsbp = String.fromCharCode(160);
        const cartSubtotal: any = parse(action.payload.cart.subtotal);
        const cartSubtotalTax: any = parse(action.payload.cart.subtotalTax);
        const cartTotalTax: any = parse(action.payload.cart.totalTax);
        const cartShippingTax: any = parse(action.payload.cart.shippingTax);
        const cartTotal: any = parse(action.payload.cart.total);
        const cartShippingTotal: any = parse(action.payload.cart.shippingTotal);
        const cartDiscountTotal: any = parse(action.payload.cart.discountTotal);

        state.bag.availableShippingMethods =
          action.payload.cart.availableShippingMethods;
        state.bag.chosenShippingMethods =
          action.payload.cart.chosenShippingMethods;
        state.bag.currency = cartSubtotal.split(nsbp)[0];
        state.bag.subtotal = Number(cartSubtotal.split(nsbp)[1]);
        state.bag.total = Number(cartTotal.split(nsbp)[1]);
        state.bag.subtotalTax = Number(cartSubtotalTax.split(nsbp)[1]);
        state.bag.totalTax = Number(cartTotalTax.split(nsbp)[1]);
        state.bag.discountTotal = Number(cartDiscountTotal.split(nsbp)[1]);
        state.bag.displayPricesIncludeTax =
          action.payload.cart.displayPricesIncludeTax;

        if (action.payload.cart.appliedCoupons) {
          const cartDiscountTax: any = parse(action.payload.cart.appliedCoupons[0].discountTax);
          if (state.bag.displayPricesIncludeTax) {
            state.bag.discountTotal = state.bag.discountTotal + Number(cartDiscountTax.split(nsbp)[1]);
          } else {
            state.bag.subtotalTax = Number(state.bag.subtotalTax - Number(cartDiscountTax.split(nsbp)[1])).toFixed(2);
          }
        }

        state.bag.shippingTax = Number(cartShippingTax.split(nsbp)[1]);
        state.bag.shippingTotal = Number(cartShippingTotal.split(nsbp)[1]);
        state.bag.shippingWithTax = shippingRateWithTax(
          state.bag.shippingTotal,
          state.bag.shippingTax,
          state.bag.displayPricesIncludeTax
        );

        state.bag.appliedCoupons = action.payload.cart.appliedCoupons;
        state.bag.appliedGiftUp = action.payload.cart.appliedGiftUp;

        const isAccessories = ((item: any) => {
          if (item[0].extraData && item[0].extraData.length > 0) {
            return item[0].extraData.find((extra: any) => extra.key === 'productType' && extra.value === 'accessories');
          }
          return false;
        });

        const newLocalBag: any = { items: [] };
        const filteredMergeForExtraDataNoLocalBag = cartMergeFunction(
          action.payload
        )?.items.filter((item) => {
          if (item[0].extraData && item[0].extraData.length > 0 && item[0].extraData[0].value !== 'Free' && !item[0].type.includes('contact-lens') && !isAccessories(item)) {
            return item;
          }
        });

        const filteredMergeForNormalNoLocalBag = cartMergeFunction(
          action.payload
        )?.items.filter((item) => {
          if (!item[0].extraData || item[0].extraData.length == 0 || item[0].extraData[0].value === 'Free' || item[0].type.includes('contact-lens') || isAccessories(item)) {
            return item;
          }
        });
        var priceTotal = 0;
        var glassesCount = 0;
        filteredMergeForExtraDataNoLocalBag?.map((nobagel: any) => {
          const addData: any = [{}];
          addData[0].type = getProductType(nobagel);
          if (addData[0].type === 'extradata') {
            addData[0].glassesData = getGlassesData(nobagel);
            glassesCount += 1;
          }

          if (addData[0].type === 'frameset') {
            addData[0].frameData = getFramesData(nobagel);
          }
          if (addData[0].type === 'dresdengo') {
            addData[0].glassesData = getDresdenGOData(nobagel);
          }


          addData[0].productId = nobagel[0].product.node.databaseId;
          addData[0].extraData = nobagel[0].extraData;
          addData[0].quantity = nobagel[0].quantity;
          addData[0].key = nobagel[0].key;
          addData[0].price = extraDataPrice(
            nobagel,
            addData,
            state.bag.displayPricesIncludeTax
          );
          priceTotal += extraDataPrice(
            nobagel,
            addData,
            state.bag.displayPricesIncludeTax
          );
          addData[0].singlePrice = extraDataSinglePrice(
            nobagel,
            addData,
            state.bag.displayPricesIncludeTax
          );
          addData[0].uuid = uuidv4();
          addData[0].subTotal = itemSubTotal(
            nobagel,
            state.bag.displayPricesIncludeTax
          );
          addData[0].subtotalTax = itemSubTotalTax(nobagel);
          newLocalBag.items.push(addData);
        });
        var contactlensTotal = 0;
        filteredMergeForNormalNoLocalBag?.map((nobagel: any) => {
          const addData: any = [{}];
          addData[0].type = nobagel[0].type;
          addData[0].databaseId = nobagel[0].databaseId;
          addData[0].quantity = nobagel[0].quantity;
          addData[0].name = nobagel[0].name;
          addData[0].slug = nobagel[0].slug;
          addData[0].hasVariation = nobagel[0].hasVariation;
          addData[0].variationSlug = nobagel[0].variationSlug;
          addData[0].image = nobagel[0].image;
          addData[0].key = nobagel[0].key;
          addData[0].price = noExtraDataPrice(
            nobagel,
            state.bag.displayPricesIncludeTax
          );

          addData[0].subTotal = noExtraDataSubTotal(
            nobagel,
            state.bag.displayPricesIncludeTax
          );

          if (nobagel[0].type && nobagel[0].type.includes('contact-lens')) {
            contactlensTotal += addData[0].subTotal;
            addData[0].singlePrice = parseFloat(Number(addData[0].subTotal / nobagel[0].quantity).toFixed(2));
          } else {
            addData[0].singlePrice = noExtraDataSinglePrice(
              nobagel,
              state.bag.displayPricesIncludeTax
            );
          }


          addData[0].subtotalTax = nobagel[0].subtotalTax;
          addData[0].uuid = uuidv4();
          priceTotal += addData[0].price;

          if ('extraData' in nobagel[0]) {
            addData[0].extraData = nobagel[0].extraData;
          }

          newLocalBag.items.push(addData);
        });

        newLocalBag['items'] = newLocalBag.items.reverse();
        state.bag.priceTotal = priceTotal;
        state.bag.items = newLocalBag.items;
        let totalCount: number = 0;
        newLocalBag.items.map((item: any) => {
          totalCount += item[0].quantity;
        });
        state.count = totalCount;

        var giftupTotal = 0;
        if (state.bag.appliedGiftUp) {
          giftupTotal = state.bag.appliedGiftUp[0].discountAmount;
        }

        let bundleTotal: any = totalBundleDiscount(
          Number(priceTotal) - Number(state.bag.discountTotal) + Number(state.bag.shippingWithTax) - Number(giftupTotal),
          Number(state.bag.total),
          Number(state.bag.totalTax),
          state.bag.displayPricesIncludeTax
        );

        let discountTotals: any = [];
        const cartDiscountDetails = JSON.parse(action.payload.cart.discountDetails);
        Object.keys(cartDiscountDetails).forEach((key) => {
          const value = cartDiscountDetails[key];
          if (value.applied_rules && value.applied_rules.length > 0) {
            value.applied_rules.forEach((rule: any) => {
              if (rule.type == 'buy_x_get_y_cheapest_from_categories_discount') {
                let discountAmount = Number(rule.discount.discount_price) * Number(rule.discount.discount_quantity);
                let getDiscountTotal = discountTotals.find((coupon: any) => coupon.code === rule.title);
                if (getDiscountTotal) {
                  getDiscountTotal.discountAmount += discountAmount;
                } else {
                  discountTotals.push({
                    code: rule.title,
                    discountAmount: discountAmount,
                    type: rule.type
                  });
                }
              } else if (rule.type == 'simple_discount') {
                let discountAmount = Number(rule.discount.discount_price) * Number(rule.discount.discount_quantity);
                let getDiscountTotal = discountTotals.find((coupon: any) => coupon.code === rule.title);
                if (getDiscountTotal) {
                  getDiscountTotal.discountAmount += discountAmount;
                } else {
                  discountTotals.push({
                    code: rule.title,
                    discountAmount: discountAmount,
                    type: rule.type
                  });
                }
              } else if (rule.type == 'buy_x_get_x_discount') {
                let discountAmount = Number(rule.discount.discount_price) * Number(rule.discount.discount_quantity);
                if (rule.discount.discount_type == 'flat') {
                  discountAmount = Number(rule.discount.discount_value);
                }

                let getDiscountTotal = discountTotals.find((coupon: any) => coupon.code === rule.title);
                if (getDiscountTotal) {
                  getDiscountTotal.discountAmount += discountAmount;
                } else {
                  discountTotals.push({
                    code: rule.title,
                    discountAmount: discountAmount,
                    type: rule.type
                  });
                }
              } else if (rule.type == 'buy_x_get_y_cheapest_from_products_discount') {
                let discountAmount = Number(rule.discount.discount_value) * Number(rule.discount.discount_quantity);
                let getDiscountTotal = discountTotals.find((coupon: any) => coupon.code === rule.title);
                if (getDiscountTotal) {
                  getDiscountTotal.discountAmount += discountAmount;
                } else {
                  discountTotals.push({
                    code: rule.title,
                    discountAmount: discountAmount,
                    type: rule.type
                  });
                }
              }
            });
          }
        });

        const excludedCoupons = ['abc40', 'value50', 'twinning', 'get40', 'welcome25', 'welcome50', 'singles', 'black50', 'dresden50', 'dresden52', 'more4less'];

        discountTotals.map((coupon: any) => {
          if (excludedCoupons.includes(coupon.code.toLocaleLowerCase()) && Number(coupon.discountAmount) != bundleTotal) {
            coupon.discountAmount = bundleTotal;
          }

          if (coupon.code.toLowerCase() === 'bundle discount') {
            return;
          }

          if (!state.bag.appliedCoupons) {
            state.bag.appliedCoupons = [];
          }

          if ((state.bag.appliedCoupons.length === 0 || !state.bag.appliedCoupons.some((appliedCoupon: any) => appliedCoupon.code.toLowerCase() === coupon.code.toLowerCase()))) {
            state.bag.appliedCoupons.push({
              code: capitalize(coupon.code),
              description: coupon.code,
              discountAmount: '$&nbsp;' + coupon.discountAmount,
            });
          }
        });

        // Get total Amount
        let exlucdeBundle = discountTotals.filter((coupon: any) => coupon.code !== 'Bundle Discount');
        if (exlucdeBundle.length > 0) {
          exlucdeBundle.map((coupon: any) => {
            bundleTotal -= coupon.discountAmount;
          });
        }



        if (discountTotals.length > 0 && state.bag.appliedCoupons && state.bag.appliedCoupons.length > 0) {
          state.bag.appliedCoupons.map((coupon: any) => {
            let getDiscountTotal = discountTotals.find((couponTotal: any) => couponTotal.code.toLocaleLowerCase() === coupon.code.toLocaleLowerCase());
            if (getDiscountTotal) {
              coupon.discountAmount = '$&nbsp;' + getDiscountTotal.discountAmount;
              state.bag.discountTotal = Number(state.bag.discountTotal) + getDiscountTotal.discountAmount
            }
          });
        }

        state.bag.bundleDiscount = bundleTotal;
        state.bag.discountTotal = Number(state.bag.discountTotal) + bundleTotal;

        state.bag.subtotal = Number(priceTotal).toFixed(2);
        state.bag.freeShipping = action.payload.cart.chosenShippingMethods.length > 0 ? (action.payload.cart.chosenShippingMethods[0].includes('free_shipping') || action.payload.cart.chosenShippingMethods[0].includes('free')) : false
        state.bag.estimatedShippingPrice = state.estimatedShippingPrice;
        state.bag.glassesCount = glassesCount;

        setCoupons(state.bag);
        if (newLocalBag.items.length > 0) {
          localStorage.setItem('local-basket', JSON.stringify(newLocalBag));
        } else {
          localStorage.removeItem('local-basket');
        }
      }
    },

    setPaymentLoading: (state, action) => {
      state.paymentLoading = action.payload;
    },
    setPaymentError: (state, action) => {
      state.paymentError = action.payload;
    },
    setPaymentErrorMessage: (state, action) => {
      state.paymentErrorMessage = action.payload;
    },
    setEstimatedShippingPrice: (state, action) => {
      state.bag.estimatedShippingPrice = action.payload;
    },
    setTotalQuantity: (state, action) => {
      state.count = action.payload;
    },
    setPeopleAlsoBoughtModal: (state, action) => {
      state.showPeopleAlsoBoughtModal = action.payload;
    },
    setPeopleAlsoBoughtModalOpenBefore: (state, action) => {
      localStorage.setItem('isShowPeopleAlsoBoughtModalOpenBefore', action.payload);
      state.isShowPeopleAlsoBoughtModalOpenBefore = action.payload;
    }
  }
});
export const {
  open,
  close,
  updateItem,
  updateBag,
  setLoading,
  updateCount,
  setLoadingUpdate,
  setSimpleLoading,
  removeItem,
  quantityUpdate,
  updateShoppingCartWithRequest,
  setRefecthQ,
  setBlock,
  setLastQueueItem,
  startQueue,
  setPaymentLoading,
  setPaymentError,
  setPaymentErrorMessage,
  setEstimatedShippingPrice,
  setUpdateElementFrame,
  setTotalQuantity,
  setPeopleAlsoBoughtModal,
  setPeopleAlsoBoughtModalOpenBefore
} = bagSlice.actions;

export default bagSlice.reducer;
